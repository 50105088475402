import React from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Label } from 'recharts';


const LoadChart = (props) => (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <ResponsiveContainer width="90%" height={500}>
            <LineChart
                data={[...props.data]}
                margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" >
                    <Label value="Hours of the day" offset={2} position="insideBottom" />
                </XAxis>
                <YAxis />
                <Tooltip formatter={(value, name, props)=> `${value} kW`}/>
                <Legend />
                <Line type="monotone" dataKey="kw" stroke="#8884d8" strokeWidth={3} />
            </LineChart>
        </ResponsiveContainer>
    </div>
);

export default LoadChart;
