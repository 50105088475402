import React from 'react';
import {
    BarChart,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
    Bar,
    Line,
} from 'recharts';


const CustomizedLabel = ({ x, y, stroke, value, unit }) => {
    return (
        <text x={x} y={y} dy={-4} fill={stroke} fontSize={10} textAnchor="middle">
            {value}kWh
        </text>
    );
};

const EnergyBarChart = (props) => (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <ResponsiveContainer width="90%" height={500}>
            <BarChart
                width={400}
                height={400}
                data={props.data}
                margin={{
                    top: 15,
                    right: 30,
                    left: 30,
                    bottom: 5,
                }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip formatter={(value, name, props)=> `${value} kWh`}/>
                <Legend />
                <Bar dataKey="kwh" fill="#8884d8" label={<CustomizedLabel />} barSize={20} />
            </BarChart>
        </ResponsiveContainer>
    </div>
);

export default EnergyBarChart;
