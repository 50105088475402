import React from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';


const VoltageChart = (props) => (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <ResponsiveContainer width="90%" height={500}>
            <LineChart
                data={[...props.data]}
                margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                {/* <Tooltip /> */}
                <Tooltip formatter={(value, name, props)=> `${value} V`}/>
                <Legend />
                <Line type="monotone" dataKey="v1" stroke="#8884d8" strokeWidth={3} />
                <Line type="monotone" dataKey="v2" stroke="#82ca9d" strokeWidth={3} />
                <Line type="monotone" dataKey="v3" stroke="#82019d" strokeWidth={3} />
            </LineChart>
        </ResponsiveContainer>
    </div>
);

export default VoltageChart;
