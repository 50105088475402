import axios from "axios";
import moment from 'moment';
// export const FILE_API_ENDPOINT = 'http://localhost:3024/';
export const FILE_API_ENDPOINT = "http://18.61.183.168:3024/";


export const API_ENDPOINT = `${FILE_API_ENDPOINT}vatio/`;


export const getAxiosInstance = () => {
    var axiosInstance = axios.create({
        timeout: 1000 * 60
    });
    axiosInstance.defaults.baseURL = API_ENDPOINT;
    return axiosInstance;
};

var axiosInstance = getAxiosInstance();


export const getGraphData = (date, device) => {
    return axiosInstance.get(`DeviceDataHistory/dayload?date=${date}&device=${device}`);
}