import React, { useState, useEffect } from 'react';
import BarChartTwo from './compoents/charttwo'
import SingleBarSingleLineChart from './compoents/chartthree'
import LineCharts from './compoents/chartfour'
import MultiTypeChart from './compoents/chartfive'
import MultiTypeBar from './compoents/chartsix'
import BarChartThree from './compoents/threebarchart';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Grid, Typography } from '@mui/material';
import moment from 'moment';
import { getGraphData } from './service';
import LoadChart from './compoents/loadchart';
import EnergyBarChart from './compoents/enargychart';
import VoltageChart from './compoents/voltagechart';


const App = () => {

  const [selDate, setSelDate] = useState();
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    if (selDate) {
      getGraphData(moment(new Date(selDate)).format("YYYY-MM-DD"), "dev_001").then((resp) => {
        console.log(resp.data);
        setChartData(resp.data);
      }).catch((err) => { console.log(err); })
    }

  }, [setChartData, selDate])

  return (
    <div className="App">
      <Grid container pt={5}>
        <Grid item xs={12} xl={12} md={12}>
          <Grid container alignItems={"center"} justifyContent={"center"}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={['DatePicker']}>
                <DatePicker value={selDate} label="Date Selector" onChange={(date) => { console.log("Selected date", date); setSelDate(date); console.log(moment(new Date(date)).format("YYYY-MM-DD")); }} />
              </DemoContainer>
            </LocalizationProvider>
          </Grid>
        </Grid>
        <Grid item xs={12} xl={12} md={12} pt={3}>
          <Grid container alignItems={"center"} justifyContent={"center"}>
            <Typography>Load Analysis</Typography>
          </Grid>
        </Grid>
        <Grid item xs={12} xl={12} md={12}>
          <LoadChart data={chartData.dayload || []} />
        </Grid>
        <Grid item xs={12} xl={12} md={12} pt={3}>
          <Grid container alignItems={"center"} justifyContent={"center"}>
            <Typography>Energy Analysis</Typography>
          </Grid>
        </Grid>
        <Grid item xs={12} xl={12} md={12}>
          <EnergyBarChart data={chartData.dayenergy || []} />
        </Grid>
        <Grid item xs={12} xl={12} md={12} pt={3}>
          <Grid container alignItems={"center"} justifyContent={"center"}>
            <Typography>Voltage Analysis</Typography>
          </Grid>
        </Grid>
        <Grid item xs={12} xl={12} md={12}>
          <VoltageChart data={chartData.dayvoltage || []} />
        </Grid>
      </Grid>


      {/* <BarChartTwo />
      <SingleBarSingleLineChart />
      <LineCharts />
      <MultiTypeChart />
      <MultiTypeBar />
      <BarChartThree /> */}
    </div>
  );
};

export default App;
